import {useInView} from 'react-intersection-observer';
import {useState, useEffect, useContext} from 'react';
import {
  Title, InfoBox, InfoImage, InfoText1, InfoText2,
  Tab, InfiniteScrollContainer, Header, HeaderItem,
  ActiveButton, InactiveButton, Container, InfoContainer, Divider, ItemContainer, ContentItem, TabContainer
} from './style';
import SolProviderContext from "../../SolProviderContext";
import {StakeClient, StakingItem} from "../../contract/stake";
import {
  bnToStringWithDecimals, calculateReward,
  convertToDateFormat,
  getSolScanAccountUrl, getUnstakingDate
} from "../../contract/util";
import {useStaking} from "../../StakingContext";
import {StakedItem, UnstakedItem} from "../../types";
import UnstakeButton from "../staking/UnstakeButton";
import {RiumClient} from "../../riumClient";

function MyPage() {
  const context = useContext(SolProviderContext);
  const {solProvider, solAddress, stakeClient}: any = context;
  const [activeTab, setActiveTab] = useState(1); // 1이면 1번 탭, 2이면 2번 탭
  const { stakedItems, setStakedItems, unstakedItems, setUnstakedItems } = useStaking();
  const [stakedAmount, setStakedAmount] = useState("0");
  const [riumPoint, setRiumPoint] = useState(0);
  const riumClient: RiumClient = new RiumClient();
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const initStakingItems = async () => {
    const rp = await riumClient.getRium(solAddress);
    setRiumPoint(rp);
    const hasStaking = await (stakeClient as StakeClient).hasStaking(solAddress);
    if (!hasStaking) {
      setStakedItems([]);
      setUnstakedItems([]);
      return;
    }

    const stakingCount = await (stakeClient as StakeClient).getAllStakingCounts(solAddress);
    if (stakingCount != stakedItems.length) {
      const stakingItems: StakingItem[] = await (stakeClient as StakeClient).getAllStakings(solAddress);
      setStakedItems(
        stakingItems
          .sort((a, b) => a.stakedAt - b.stakedAt)
          .map((item): StakedItem => {
          const stakedAtDate = new Date(item.stakedAt * 1000);
          const now = new Date(Date.now());
          const days = item.months * 30;
          const unstakingDate = getUnstakingDate(stakedAtDate, days)
          return {
            id: item.id,
            explorer: getSolScanAccountUrl(item.pda),
            stakedDate: convertToDateFormat(stakedAtDate),
            endDate: convertToDateFormat(unstakingDate),
            quantity: bnToStringWithDecimals(item.quantity),
            interest: bnToStringWithDecimals(calculateReward(item.quantity, item.months)),
            months: item.months,
            canUnstake: now >= unstakingDate && item.isFirst
          }
        })
      );

      const amountSum = stakingItems
        .map(s => s.quantity)
        .reduce((a, b) => a.add(b))

      setStakedAmount(bnToStringWithDecimals(amountSum))
    }

    const unstakingCount = await (stakeClient as StakeClient).getAllUnstakingCounts(solAddress);
    if (unstakingCount != unstakedItems.length) {
      const unstakingItems: StakingItem[] = await (stakeClient as StakeClient).getAllUnstakings(solAddress);
      setUnstakedItems(
        unstakingItems
          .sort((a, b) => a.stakedAt - b.stakedAt)
          .map((item): UnstakedItem => {
          const stakedAtDate = new Date(item.stakedAt * 1000);
          const now = new Date(Date.now());
          const days = item.months * 30;
          const unstakingDate = getUnstakingDate(stakedAtDate, days)
          return {
            id: item.id,
            explorer: getSolScanAccountUrl(item.pda),
            stakedDate: convertToDateFormat(stakedAtDate),
            endDate: convertToDateFormat(unstakingDate),
            quantity: bnToStringWithDecimals(item.quantity),
            interest: bnToStringWithDecimals(calculateReward(item.quantity, item.months)),
            months: item.months,
            canUnstake: now >= unstakingDate,
            days: days,
          }
        })
      );
    }
  }

  useEffect(() => {
    if (!solProvider) {
      setStakedItems([]);
      setUnstakedItems([]);
      setStakedAmount("0");
      setRiumPoint(0);
      return;
    }

    initStakingItems();
  }, [solProvider])

  useEffect(() => {
    if (inView) {
      fetchMoreData();
    }
  }, [inView]);

  const fetchMoreData = async () => {
    // TODO
  };

  return (
    <Container>
      <Title>MY PAGE</Title>
      <InfoContainer>
        <InfoBox>
          <InfoImage src={"/images/paz_token_black.svg"}/>
          <InfoText1>Staked Amount</InfoText1>
          <InfoText2>{stakedAmount}</InfoText2>
        </InfoBox>
        <InfoBox>
          <InfoImage src={"/images/rium.svg"}/>
          <InfoText1>Rium</InfoText1>
          <InfoText2>{riumPoint}</InfoText2>
        </InfoBox>
      </InfoContainer>
      <TabContainer>
        <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Current</Tab>
        <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>History</Tab>
      </TabContainer>
      <InfiniteScrollContainer>
        <Header>
          <HeaderItem>EXPLORER</HeaderItem>
          <HeaderItem>STAKED DATE</HeaderItem>
          {activeTab === 1 ? <HeaderItem>EXPECTED END DATE</HeaderItem> : <HeaderItem>RESULT</HeaderItem>}
          <HeaderItem>STAKED QUANTITY</HeaderItem>
          <HeaderItem>INTEREST</HeaderItem>
          {activeTab === 1 ? <HeaderItem></HeaderItem> : <HeaderItem>PRODUCT</HeaderItem>}
        </Header>
        <Divider src="/images/my-page-vector.svg" alt="divider"/>
        {/* Tab에 따라 다르게 렌더링 될 컨텐츠 */}
        {activeTab === 1 ? (
          stakedItems?.map((item, index) => (
            <ItemContainer key={index}>
              <div style={{display: 'flex', width: '100%'}}>
                <ContentItem style={{color: "#1037FF"}}>
                  <a style={{textDecoration: "none"}} href={item.explorer} target="_blank" rel="noopener noreferrer">
                    In Solscan
                  </a>
                </ContentItem>
                <ContentItem>{item.stakedDate}</ContentItem>
                <ContentItem>{item.endDate}</ContentItem>
                <ContentItem>{item.quantity}</ContentItem>
                <ContentItem>{item.interest}</ContentItem>
                <ContentItem>
                  <UnstakeButton months={item.months} stakingId={item.id} canStake={item.canUnstake}></UnstakeButton>
                </ContentItem>
              </div>
              <Divider src="/images/my-page-vector.svg" alt="divider"/>
            </ItemContainer>
          ))
          // 1번 탭의 아이템들과 버튼을 여기에 렌더링
        ) : (
          unstakedItems?.map((item, index) => (
            <ItemContainer key={index}>
              <div style={{display: 'flex', width: '100%'}}>
                <ContentItem style={{color: "#1037FF"}}>
                  <a style={{textDecoration: "none"}} href={item.explorer} target="_blank" rel="noopener noreferrer">
                    In Solscan
                  </a>
                </ContentItem>
                <ContentItem>{item.stakedDate}</ContentItem>
                <ContentItem>SUCCESS</ContentItem>
                <ContentItem>{item.quantity}</ContentItem>
                <ContentItem>{item.interest}</ContentItem>
                <ContentItem>
                  {item.days}days
                </ContentItem>
              </div>
              <Divider src="/images/my-page-vector.svg" alt="divider"/>
            </ItemContainer>
          ))
          // 2번 탭의 아이템들과 String 값을 여기에 렌더링
        )}
        <div ref={ref}></div>
      </InfiniteScrollContainer>
    </Container>
  );
}

export default MyPage;