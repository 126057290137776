import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 180px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 60px;
    margin: auto;
    margin-top: 90px;
    margin-bottom: 60px;
  }
`;

export const LeftContentWrapper = styled.div`
  max-width: 508px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 360px;
  }
`;

export const Title = styled.h1`
  color: #000;
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  
  @media (max-width: 768px) {
    font-size: 52px;
    margin-bottom: 10px;
  }
`;

export const Description = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 50px;
  white-space: pre-line;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const Info = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
  position: relative;
  text-align: left;

  &::before {
    content: "•";
    position: absolute;
    left: 5px; 
    top: 0;
  }

`;

export const RightContentWrapper = styled.div`
  max-width: 508px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 360px;
  }
`;

export const TotalTab = styled.div`
  height: 42px;
  width: 508px;
  background: #000;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  border: 1.5px solid #000;

  @media (max-width: 768px) {
    width: 360px;
  }
`;

export const TotalRiumText = styled.span`
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 21px;
`;

export const TabButton = styled.button`
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 32px;
  // 추가 버튼 스타일
`;

export const Box = styled.div`
  height: 90px;
  width: 508px;
  flex-shrink: 0;
  border: 1.5px solid #000;
  background: #FFF;
  @media (max-width: 768px) {
    width: 360px;
  }
`;

export const BoxTitle = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-left: 28px;
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BoxValue = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  color: ${(props) => props.color || "#000"};
`;

export const ChangeButton = styled.button<{ image?: any }>`
  position: absolute; // 버튼을 절대 위치로 설정
  left: 50%; // 버튼을 상자의 중간에 위치시킴
  transform: translate(-50%, -50%); // 버튼을 정확히 중앙으로 이동
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 40px; // 버튼의 너비
  height: 40px; // 버튼의 높이
  border: none;
  cursor: pointer;
`;

export const BoxesContainer = styled.div`
  position: relative; // 상대 위치로 설정하여 내부의 절대 위치 요소를 기준으로 함
`;