import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;  // 왼쪽 정렬을 위한 변경
  gap: 20px;  // 컴포넌트 간 간격. 필요에 따라 조절 가능.
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;  // 좌우 패딩 추가
  margin-top: 140px;
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 23px;
  margin-bottom: 100px;
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 0px;
`;

export const Title = styled.h1`
  width: 503px;
  height: 101px;
  flex-shrink: 0;
  color: #000;
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
`;

export const InfoBox = styled.div`
  width: 384px;
  height: 90px;
  flex-shrink: 0;
  border: 1.5px solid #000;
  background: #FFF;
  display: flex;
  align-items: center;
  position: relative;
`;

export const InfoImage = styled.img`
  margin-left: 32px;
  margin-right: 3px; // 예시: 사이 간격
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const InfoText1 = styled.p`
  font-family: Poppins;
  font-size: 16px;
  margin-right: 30px;
  color: #000;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%; /* 21.04px */
`;

export const InfoText2 = styled.p`
  position: absolute;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  right: 40px;
`;

export const ActiveButton = styled.button`
  width: 204px;
  height: 52px;
  flex-shrink: 0;
  background: #000;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px; // 예시: 크기는 실제 요구에 따라 조정 필요
  font-weight: 500;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const InactiveButton = styled(ActiveButton)`
  opacity: 0.1;
  background: #000;
  color: #FFF;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column; // 수직 방향으로 아이템 배열
  width: 100%; // 헤더와 동일한 너비
`;

export const InfiniteScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 500px; // 예시: 화면에 맞는 적절한 높이를 설정해주세요
  width: 100%;
  margin-left: 0; // 이 부분을 추가하여 왼쪽 시작점을 Tab과 동일하게 만듭니다.
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white; // 스크롤 시에도 헤더가 보이게 하려면 배경 색상이 필요
  z-index: 1;
`;

export const HeaderItem = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px; // 예시: 각 항목의 패딩. 실제 요구에 따라 조정 필요
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const ContentItem = styled.div`
  flex: 1;
  display: flex;         // flexbox 사용
  align-items: center;   // 수직 중앙 정렬
  justify-content: center; // 수평 중앙 정렬
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600; // 내용 항목은 헤더보다 덜 굵게 스타일링되었다고 가정
`;

export const Tab = styled.button<{ active?: boolean }>`
  width: 160px;
  height: 54px;
  flex-shrink: 0;
  border: 1.5px solid #000;
  background: ${(props) => props.active ? '#000' : '#FFF'};
  color: ${(props) => props.active ? '#FFF' : '#000'};
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%;
`;

export const Button = styled.button`
  width: 204px;
  height: 52px;
  flex-shrink: 0;
  background: #000;
  color: #FFF;
  opacity: ${props => props.disabled ? 0.1 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

export const Divider = styled.img`
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`;