import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;  // 컴포넌트 간 간격. 필요에 따라 조절 가능.
  width: 100%;
  max-width: 500px;
  padding: 0 20px;  // 좌우 패딩 추가
  margin-top: 140px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;  
  align-items: center;
  gap: 23px;
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0px;
  justify-content: center;  // 수평 중앙 정렬
  margin-top: 30px;
`;

export const Title = styled.h1`
  width: 100%;

  max-width: 360px; // StakingItems와 동일한 최대 너비를 갖도록 설정
  color: #000;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  text-align: left;
  margin-top: 0px;
  margin-bottom: 3px;
`;

export const InfoBox = styled.div`
  width: 360px;
  height: 80px;
  flex-shrink: 0;
  border: 1.5px solid #000;
  background: #FFF;
  display: flex;
  align-items: center;
  position: relative;
`;

export const InfoImage = styled.img`
  margin-left: 15px;
  margin-right: 3px; // 예시: 사이 간격
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const InfoText1 = styled.p`
  font-family: Poppins;
  font-size: 16px;
  margin-right: 30px;
  color: #000;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%; /* 21.04px */
`;

export const InfoText2 = styled.p`
  position: absolute;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  right: 40px;
`;

export const ActiveButton = styled.button`
  width: 100%;
  max-width: 350px;
  height: 52px;
  flex-shrink: 0;
  background: #000;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px; // 예시: 크기는 실제 요구에 따라 조정 필요
  font-weight: 500;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const InactiveButton = styled(ActiveButton)`
  opacity: 0.1;
  background: #000;
  color: #FFF;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column; // 수직 방향으로 아이템 배열
  width: 100%; // 헤더와 동일한 너비
`;

export const InfiniteScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 500px; // 예시: 화면에 맞는 적절한 높이를 설정해주세요
  width: 100%;
  margin-left: 0; // 이 부분을 추가하여 왼쪽 시작점을 Tab과 동일하게 만듭니다.
`;

export const ContentItem = styled.div`
  flex: 1;
  display: flex;         // flexbox 사용
  align-items: center;   // 수직 중앙 정렬
  justify-content: center; // 수평 중앙 정렬
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600; // 내용 항목은 헤더보다 덜 굵게 스타일링되었다고 가정
`;

export const Tab = styled.button<{ active?: boolean }>`
  width: 180px;
  height: 54px;
  flex-shrink: 0;
  border: 1.5px solid #000;
  background: ${(props) => props.active ? '#000' : '#FFF'};
  color: ${(props) => props.active ? '#FFF' : '#000'};
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.5%;
`;

export const Button = styled.button`
  width: 204px;
  height: 52px;
  flex-shrink: 0;
  background: #000;
  color: #FFF;
  opacity: ${props => props.disabled ? 0.1 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

export const Divider = styled.img`
  width: 100%;
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
`;

// 1. RowContainer: 헤더와 컨텐츠가 함께 들어갈 행 컨테이너
export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// 2. Header: 헤더의 스타일
export const Header = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  flex: 1;
  text-align: left;
  margin-left: 10px;
`;

export const ContentWrapper = styled.div`
  flex: 2; 
  display: flex;
  justify-content: flex-end; // 오른쪽 정렬
`;

export const Content = styled.div`
  text-align: left; // 왼쪽으로 텍스트 정렬
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: calc(100% - 150px); // 원하시는 너비 설정. 60px는 예시입니다.
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;  // 필요에 따라 너비 조절 가능
  max-width: 360px;
  margin-top: 10px;
  margin-bottom: 40px;
  gap: 7px;
`;