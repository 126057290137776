import React, {createContext, useCallback, useContext, useState} from 'react';
import WebNavbar from './web/navbar/Navbar';
import WebFooter from './web/footer/Footer';
import WebAboutPage from "./web/about/AboutPage";
import WebWorkInProcess from "./web/WorkInProcess";
import WebStakingPage from "./web/staking/StakingPage";
import MobileNavbar from './mobile/navbar/Navbar';
import MobileWorkInProcess from "./mobile/WorkInProcess";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import styled from "styled-components";
import SolProviderContext, {SolProvider} from "./SolProviderContext";
import {Buffer} from 'buffer';
import {BrowserView, MobileView} from 'react-device-detect'
import MobileStakingPage from "./mobile/staking/StakingPage";
import MobileFooter from "./mobile/footer/Footer";
import MobileAboutPage from "./mobile/about/AboutPage";
import WebStakingInPage from "./web/staking/StakingInPage";
import PrivacyAndTermPage from "./PrivacyAndTermPage";
import WebMyPage from "./web/mypage/MyPage";
import {StakeClient} from "./contract/stake";
import {StakingProvider} from './StakingContext';
import MobileMyPage from "./mobile/mypage/MyPage";
import SwapPage from "./web/swap/SwapPage";
import {Verification} from "./mobile/pararium/Verification";

window.Buffer = Buffer

const AppContainer = styled.div`
  min-height: 100vh; // 화면의 전체 높이를 차지하도록 설정합니다
  display: flex;
  position: relative;
  flex-direction: column; // 컨텐츠를 수직 방향으로 배열합니다.
  background-color: #FFF; // phantom in-app brower에서 background가 무시되는 버그가 존재함.
`;

const Content = styled.div`
  flex: 1; // 가능한 모든 여백을 사용하여 Content를 확장합니다.
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const [solProvider, setSolProvider] = useState();
  const [stakeClient, setStakeClient] = useState<StakeClient | undefined>();

  return (
    <div>
      <BrowserView>
        <SolProvider value={{solProvider, setSolProvider, stakeClient, setStakeClient}}>
          <StakingProvider>
            <AppContainer className="App">
              <Router>
                <WebNavbar/>

                <Content>
                  <Routes>
                    <Route path="/" element={<WebAboutPage/>}/>
                    <Route path="/about" element={<WebAboutPage/>}/>
                    <Route path="/swap" element={<SwapPage/>}/>
                    <Route path="/staking" element={<WebStakingPage/>}/>
                    <Route path="/staking-in" element={<WebStakingInPage/>}/>
                    <Route path="/dashboard" element={<WebWorkInProcess/>}/>
                    <Route path="/my-page" element={<WebMyPage/>}/>
                    <Route path="/terms" element={<PrivacyAndTermPage/>}/>
                    {/* 추가적인 라우트... */}
                  </Routes>
                </Content>

                <WebFooter/>
              </Router>
            </AppContainer>
          </StakingProvider>
        </SolProvider>
      </BrowserView>
      <MobileView>
        <div style={{overflowX: "hidden"}}>
          <Router>
            <SolProvider value={{solProvider, setSolProvider, stakeClient, setStakeClient}}>
              <StakingProvider>
                <AppContainer className="App">
                  <MobileNavbar/>
                  <Content>
                    <Routes>
                      <Route path="/" element={<MobileAboutPage/>}/>
                      <Route path="/about" element={<MobileAboutPage/>}/>
                      <Route path="/swap" element={<SwapPage/>}/>
                      <Route path="/staking" element={<MobileStakingPage/>}/>
                      <Route path="/staking-in" element={<WebStakingInPage/>}/>
                      <Route path="/dashboard" element={<MobileWorkInProcess/>}/>
                      <Route path="/my-page" element={<MobileMyPage/>}/>
                      <Route path="/terms" element={<PrivacyAndTermPage/>}/>
                      <Route path="/verify/:token" element={<Verification/>}/>
                      {/* 추가적인 라우트... */}
                    </Routes>
                  </Content>
                  <MobileFooter/>
                </AppContainer>
              </StakingProvider>
            </SolProvider>
          </Router>
        </div>
      </MobileView>
    </div>
  );
}

export default App;
