import React from 'react';
import {SyncLoader} from "react-spinners";
import styled from "styled-components";

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; 
`;

function Loading() {
  return (
    <LoadingOverlay>
      <SyncLoader></SyncLoader>
    </LoadingOverlay>
  )
}

export default Loading